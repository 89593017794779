<template>
  <div>
    <h3 class="p-2 p-lg-3 my-1 my-lg-3">Studies</h3>
    <!--begin::Form-->
    <form class="p-2 p-lg-3">
      <div class="d-block">
        <div class="input-icon h-40px">
          <input
            type="text"
            class="form-control form-control-lg form-control-solid h-40px"
            placeholder="Search keyword ..."
            v-model="search_phrase"
          />
          <span>
            <span class="svg-icon svg-icon-lg">
              <!--begin::Svg Icon-->
              <inline-svg src="media/svg/icons/General/Search.svg" />
              <!--end::Svg Icon-->
            </span>
          </span>
        </div>
      </div>
    </form>
    <br />
    <!--end::Form-->
    <!--begin::List-->
    <div class="list list-hover">
      <!--begin::Item-->
      <div
        v-for="study in studies"
        v-bind:key="study.name"
        class="list-item hoverable p-2 p-lg-3 mb-2"
        @click="refresh"
      >
        <router-link
          :to="'/study/' + study.id"
          class="text-muted text-hover-primary font-weight-bold"
        >
          <div class="d-flex align-items-center">
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-dark-75 font-size-h6 mb-0">
                {{ study.name }}
              </span>
              {{ study.description }}
            </div>
            <!--begin::End-->
          </div>
        </router-link>
      </div>
      <!--end::Item-->
    </div>
    <!--end::List-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "HAStudiesMenu",
  data() {
    return {
      search_phrase: "",
      awaitingSearch: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    studies() {
      return this.$store.state.studies.studies;
    },
  },
  mounted() {
    this.search_phrase = "";
    this.refresh();
  },
  watch: {
    search_phrase: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.refresh();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    async refresh() {
      await ApiService.get("/studies", {
        params: {
          keyword: this.search_phrase,
        },
      }).then(({ data }) => {
        this.$store.commit("SET_STUDIES", data.data);
      });

      this.$emit("hideAsideMenu");
    },
  },
};
</script>
